import {translate} from "./functions";

const $ = require('jquery');
const { openConfirmation, toggleSectionContentVisibility, checkPhoneFields} = require("./functions");

export function updatePosListUser(posSelect)
{
    posSelect.change(function(){
        let initialPosId = $(this).data('pos-id');
        let posIdSelected = $(this).val();
        let interestId = $(this).data('interest');
        let url = $(this).data('url') + `?pos-id=${posIdSelected}&interest-id=${interestId}`;
        if (typeof userId !== 'undefined' && initialPosId == posIdSelected) {
            url += `&current-user-id=${userId}`;
        }
        let associateSellerSelectDisplayed = $(this).siblings('div.reaffectPosLeadLine-sellerSelect');
        let associateSellerSelect = $(this).siblings('select.reaffectPosLeadLine-sellerSelect');
        let associateSellerSelectLoader = $(this).siblings('.reaffectPosLeadLine-sellerSelectLoader');
        let requestHeaders = new Headers({
            'X-Requested-With': 'XMLHttpRequest'
        });
        let requestInit = { method: 'GET',
            headers: requestHeaders,
        };

        associateSellerSelectDisplayed.addClass('--hidden');
        associateSellerSelectLoader.removeClass('--hidden');

        fetch(url, requestInit)
            .then(response => response.json())
            .then(data => {
                associateSellerSelect.find('option').remove();
                data.forEach((user) => {
                    associateSellerSelect.append($('<option/>', {
                        'value': user.id,
                        'text': user.firstName + ' ' + user.lastName
                    }))
                })

                associateSellerSelect.select2()
                associateSellerSelect.removeClass('--hidden');
                $('.reaffectPosLeadLine-sellerSelectLoader').addClass('--hidden');
            })
            .catch(function(error) {
            });
    })
}

$(function ()
{
    const container = $('.main-content-resp.--seller');
    const containerCallUser = $('.main-content-resp.--call-user');

    if (containerCallUser.length > 0 || container.length > 0) {
        function handleForceRoute()
        {
            if (forceRoute !== '') {
                let errorMessage = translate('admin.reset_email.already_requested');
                errorMessage += ' ' + translate('admin.reset_email.force');
                openConfirmation({'text': errorMessage, 'redirectUrl': forceRoute});
            }
        }
        handleForceRoute();
    }

    if (container.length > 0) {

        const formUserArchiveModalLink = container.find("#user-archive-form-modal-link");
        const formClosePosModalLink = container.find("#user-close-pos-form-modal-link");
        const formClosePosModalContent = container.find(".user-close-pos-form-modal-content");
        const formClosePosModalLoader = formClosePosModalContent.find(".common-loader");
        const selectManager = container.find('.pos-select-manager');
        const selectRoles = container.find('.pos-select-roles');
        const selectOrganizationsLoader = container.find('.organizations-loader-wrapper');
        const selectOrganizationsWrapper = container.find('.field-organizations-wrapper');
        const selectOrganizations = container.find('.select-organizations');
        const selectBilledPosLoader = container.find('.billed-pos-loader-wrapper');
        const selectBilledPosWrapper = container.find('.select-billed-pos-wrapper');
        const selectBilledPos = container.find('.select-billed-pos');
        const selectOrganisationMinimumErrorMessage = container.find('.organisation-error-min');
        const selectAssignedSources = container.find('.select-assigned-source');
        const availableForLeadInput = container.find('#seller_availableForLead');
        const externalBillingRoleInput = container.find('#seller_rightExternalBilling');
        const monthlyStatRoleInput = container.find('#seller_monthlyStatExport');
        const internalStatRoleInput = container.find('#seller_internalStats');
        const affectationSection = container.find('.affectation_section');
        const interestLoader = container.find('.assigned-interest-loader-wrapper');
        const interestInputsWrapper = container.find('.assigned_interest_bloc');
        const interestInputs = container.find('.assigned_interest_input');
        const archiveUserLink = container.find('.archive-user-link');
        const unarchiveUserLink = container.find('#user-unarchive-link');
        const unarchiveUserLoader = container.find('.user-unarchive-loader');
        const monthlyReportEmailCheckbox = container.find('input[type="checkbox"][value="MONTHLY_REPORT_CRON"]');
        const internalStatsCheckbox = container.find('input[type="checkbox"][value="ROLE_INTERNAL_STATS"]');
        const blockLibelles = container.find('#blockLibelles');
        const selectStatLibelle = container.find('.select-stat-libelle');


        let originalInterests = [];
        let removedInterests = [];
        let confirmationState = false

        initializeSelect2();
        initializeFormUserModal();
        initializeBilledPosField();
        onlyNumber()
        handleAcceptedMailTypes();
        formValidation();
        availableForLeadHandlerAction();
        monthlyStatRoleHandlerAction();
        internalStatRoleHandlerAction();
        initInterestInputs();
        handleSelectOrganization();
        handleRemoveOrganization();
        handleArchiveUserAction();
        handleUnarchiveUserAction();
        handleChangeRoleAction();
        statUserToggle();

        function initializeSelect2()
        {
            selectRoles.select2();
            selectBilledPos.select2();
            selectAssignedSources.select2();
            selectManager.select2({
                allowClear : true,
            });
            selectOrganizations.select2({
                allowClear : true,
            });
            selectStatLibelle.select2();
        }

        function initializeFormUserModal()
        {
            formUserArchiveModalLink.on('click', function(event) {
                event.preventDefault();
                openConfirmation({'class': '--white --high', 'element': $("#user-archive-form-modal"), 'afterClose': afterClose, 'action': validateFormAction});
            })
            formClosePosModalLink.on('click', function(event) {
                event.preventDefault();
                openConfirmation({'class': '--white --high', 'element': $("#user-close-pos-form-modal"), 'afterClose': afterClose, 'action': validateFormAction});
            })
        }

        function afterClose()
        {
            formClosePosModalContent.html('');
            formClosePosModalLoader.removeClass('--hidden');
            selectOrganizationsLoaded();
            // reset popin
            $.get(Routing.generate('default_popin'), function (data) {
                $('.pop-confirmation').replaceWith(data);
            });
        }

        function onlyNumber()
        {
            $('.phone-number').keyup(function () {
                let value = $(this).val().replace(/[^0-9]/, '');
                $(this).val(value);
            });
        }

        function handleRelaunchAction($element)
        {
            let isChecked = $element.is(':checked');
            if ($element.val() === 'LEAD_NEW') {
                if (isChecked === false) {
                    $('.LEAD_NOTIFICATION_WAITING_CRON, .LEAD_NOTIFICATION_LATE_CRON').addClass('--hidden').find('input').prop('checked', false);
                } else {
                    $('.LEAD_NOTIFICATION_WAITING_CRON').removeClass('--hidden');
                }
            }
            if ($element.val() === 'LEAD_NOTIFICATION_WAITING_CRON') {
                if (isChecked === false) {
                    $('.LEAD_NOTIFICATION_LATE_CRON').addClass('--hidden').find('input').prop('checked', false);
                } else {
                    $('.LEAD_NOTIFICATION_LATE_CRON').removeClass('--hidden');
                }
            }
        }

        function handleAcceptedMailTypes()
        {
            //// ENABLE MAIL INPUT ACTION ////
            let handleIsCheckedAction = function(elem){
                let isChecked = elem.is(':checked');
                let allTypesMailAccepted = $('.types-mails-accepted input');
                allTypesMailAccepted.each(function(index){
                    if (isChecked === false) {
                        $(this).prop('checked', isChecked).trigger('change');
                    }
                    else if (index === 0 || index === 9 || index === 12) { // Only "Nouveau lead", "Nouveau commentaire", "Traitement hebdomadaire"
                        $(this).prop('checked', isChecked).trigger('change');
                    }
                });
            };
            // Handle on page loaded
            let enableMailInput = $('.enable-mail-input');
            enableMailInput.each(function(){
                if ($(this).is(':checked') === false) {
                    handleIsCheckedAction($(this))
                }
            });
            // Handle on input click
            enableMailInput.click(function(){
                handleIsCheckedAction($(this))
            });

            //// TYPES MAILS ACCEPTED INPUT ACTION ////
            let typesMailAccepted = $('.types-mails-accepted input');
            typesMailAccepted.change(function(){
                let enableEmailInput = $('.enable-mail-input');
                let isChecked = $(this).is(':checked');
                if (isChecked === true) {
                    if(enableEmailInput.is(':checked') === false) {
                        enableEmailInput.prop('checked', true);
                    }
                } else {
                    let typesMailAcceptedChecked = $('.types-mails-accepted input:checked');
                    if (typesMailAcceptedChecked .length === 0) {
                        enableEmailInput.prop('checked', false);
                    }
                }
                handleRelaunchAction($(this));
            });
            typesMailAccepted.each(function() {
                handleRelaunchAction($(this));
            })

            // For creation form, mails are enabled by default
            if (enableMailInput.hasClass('createForm')) {
                enableMailInput.click();
            }
        }
        function formValidation()
        {
            $('.main-content-resp form').submit(function(e){
                const baseForm = $(this);
                let checkPhone = checkPhoneFields('seller');
                if (!checkPhone) {
                    return false;
                }
                if (removedInterests.length === 0) {
                    return true;
                } else {

                    if (!confirmationState) {

                        e.preventDefault();

                        let url = Routing.generate('user_remove_interest', {'user': userId, 'interests': removedInterests});

                        let requestHeaders = new Headers({
                            'X-Requested-With': 'XMLHttpRequest'
                        });
                        let requestInit = { method: 'GET',
                            headers: requestHeaders,
                        };

                        fetch(url, requestInit)
                            .then(response => response.json())
                            .then(data => {
                                if (data.ok) {
                                    // on soumet le formulaire utilisateur
                                    confirmationState = true;
                                    baseForm.submit();
                                    return true;
                                }
                                else if (data.template) {
                                    openConfirmation({'class': '--white --high', 'element': $("#user-archive-form-modal"), 'afterClose': afterClose, 'action': validateFormAction});
                                    $('.user-archive-form-modal-content').html(data.template).removeClass('--hidden');
                                    $('.user-archive-form-modal-loader-wrapper').addClass('--hidden');
                                    let form = $('.reassign-lead-form-wrapper');
                                    form.find('.confirm').on('click', function() {
                                        validateFormAction(baseForm);
                                    })
                                    form.find('.cancel').on('click', function() {
                                        $('body > .pop-overlay').addClass('--hidden');
                                        $('body > .pop-confirmation').addClass('--hidden');
                                        return false;
                                    })

                                    const posSelect = $('.reaffectPosLeadLine-posSelect');
                                    const sellerSelect = $('.reaffectPosLeadLine-sellerSelect');

                                    posSelect.select2();
                                    sellerSelect.select2();
                                    updatePosListUser(posSelect);
                                }
                            })
                            .catch(function(error) {
                                console.log(error);
                                console.log('Server error');
                            });
                    }
                }
                return true;
            });
        }

        function availableForLeadHandlerAction()
        {
            if (!availableForLeadInput.is(':checked'))
            {
                affectationSection.addClass('--hidden');
            }

            availableForLeadInput.change(function(){
                const isChecked = $(this).is(':checked');
                if (isChecked) {
                    affectationSection.removeClass('--hidden');
                } else {
                    affectationSection.addClass('--hidden');
                }
            });
        }

        function monthlyStatRoleHandlerAction()
        {
            if (!monthlyStatRoleInput.is(':checked'))
            {
                monthlyReportEmailCheckbox.parents('.field').addClass('--hidden');
            }

            monthlyStatRoleInput.change(function(){
                const isChecked = $(this).is(':checked');
                if (isChecked) {
                    monthlyReportEmailCheckbox.parents('.field').removeClass('--hidden');
                } else {
                    monthlyReportEmailCheckbox.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                }
            });
        }

        function internalStatRoleHandlerAction()
        {
            if (!internalStatRoleInput.is(':checked'))
            {
                internalStatsCheckbox.parents('.field').addClass('--hidden');
            }

            internalStatRoleInput.change(function(){
                const isChecked = $(this).is(':checked');
                if (isChecked) {
                    internalStatsCheckbox.parents('.field').removeClass('--hidden');
                } else {
                    internalStatsCheckbox.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                }
            });
        }

        function initInterestInputs()
        {
            $.each(interestInputs, function(){
                $(this).closest( ".field" ).addClass('--hidden');
            });
            updateAssignedInterestsInputList()
        }

        function handleSelectOrganization()
        {
            selectOrganizations.on('select2-selecting', function(e){
                let selectedOrganizationId = e.val;
                if (typeof userId !== 'undefined' && typeof addUserOrganizationPath !== 'undefined') {
                    ajaxRequestOrganisation(addUserOrganizationPath, selectedOrganizationId);
                } else {
                    updateBilledPosSelect(selectedOrganizationId);
                }
            })
            selectOrganizations.on('change', function(e){
                if (typeof userId !== 'undefined' && typeof addUserOrganizationPath !== 'undefined') {
                } else {
                    updateAssignedInterestsInputList();
                }
            })
        }

        function handleRemoveOrganization()
        {
            selectOrganizations.on('select2-removing', async function(e){
                e.preventDefault();

                let removedOrganizationId = e.val;

                if (selectOrganizations.val().length === 1) {
                    selectOrganisationMinimumErrorMessage.removeClass('--hidden').delay(2000).addClass('--hidden');
                    return false;
                }

                selectOrganizationsLoading();

                if (typeof userId !== 'undefined' && typeof removeUserOrganizationPath !== 'undefined') {
                    if (!await ajaxCheckIfOrganizationCanBeRemoved(removedOrganizationId)) {
                        return false;
                    }
                    ajaxRequestOrganisation(removeUserOrganizationPath, removedOrganizationId);
                } else {
                    updateAssignedInterestsInputList();
                    updateBilledPosSelect(removedOrganizationId, 'forceRemove');
                }
                selectOrganizationsLoaded();
                unselectSelect2Option(selectOrganizations, removedOrganizationId);
            })
        }

        function unselectSelect2Option(select2Element, optionValue)
        {
            let select2OptionToDelete = select2Element.find('option[value="'+ optionValue +'"]');
            select2OptionToDelete.prop('selected', false);
            select2Element.trigger('change');
        }

        async function ajaxCheckIfOrganizationCanBeRemoved(posToRemoveId)
        {
            let canBeRemoved = false

            if (typeof checkUserPosCanBeDeletedPath !== 'undefined' && typeof userId !== 'undefined') {
                await $.post(checkUserPosCanBeDeletedPath, { posToRemoveId: posToRemoveId, userId: userId })
                    .done(function(data) {
                        if (data.error) {
                            canBeRemoved = false;
                        }
                        if (data.template) {
                            formClosePosModalLoader.addClass('--hidden');
                            formClosePosModalContent.html(data.template);
                            formClosePosModalLink.click();
                            fillFormModalBillingInput(posToRemoveId);
                            canBeRemoved = false;
                            const posSelect = $('.reaffectPosLeadLine-posSelect');
                            const sellerSelect = $('.reaffectPosLeadLine-sellerSelect');

                            posSelect.select2();
                            sellerSelect.select2();
                            updatePosListUser(posSelect);
                        }
                        if (data.canBeRemoved) {
                            canBeRemoved = true;
                        }
                    })
                    .fail(function() {
                        canBeRemoved = false;
                    })
                ;
            }

            return canBeRemoved;
        }

        function fillFormModalBillingInput(posIdToNotInclude)
        {
            let selectedOrganizations = selectOrganizations.val();
            let reassignPosBillingSelect = $('.reassignPosBilling');

            selectedOrganizations.forEach(function(val){
                if (val !== posIdToNotInclude) {
                    reassignPosBillingSelect.append($('<option>', {
                        value: val,
                        text: selectOrganizations.find('option[value="' + val + '"]').text()
                    }));
                }
            });

            reassignPosBillingSelect.select2();
        }

        async function ajaxRequestOrganisation(url, organizationId)
        {
            if (typeof userId !== 'undefined') {
                selectOrganizationsWrapper.addClass('--hidden');
                selectOrganizationsLoader.removeClass('--hidden').find('.common-loader').removeClass('--hidden');
                $.post(url, { userId: userId, organizationId: organizationId })
                    .done(function(response) {
                        if (response['status-code'] === 200) {
                            selectOrganizationsWrapper.removeClass('--hidden');
                            selectOrganizationsLoader.addClass('--hidden');
                            updateAssignedInterestsInputList();
                            updateBilledPosSelect();
                        }
                    });
            }
        }

        function updateAssignedInterestsInputList()
        {
            let posIds = selectOrganizations.val();

            if (posIds.length !== 0 && typeof posAvailableInterestIdsPath !== 'undefined') {
                interestLoader.removeClass('--hidden');
                interestInputsWrapper.addClass('--hidden');
                $.post(posAvailableInterestIdsPath, { posIds: posIds })
                    .done(function(response) {
                        let availableInterestIds = typeof response['available_interest_ids'] === 'object' ?
                            Object.values(response['available_interest_ids']) :
                            response['available_interest_ids']
                        ;
                        manageInterestsDisplay(availableInterestIds);
                    });
            }
            // si on décoche un univers : popin de transfert si ce vendeur a des leads dans cet univers
            $.each(interestInputs, function(){
                if ($(this).prop('checked') === true) {
                    originalInterests.push($(this).val());
                }
                $(this).on('change', function (e){
                    if ($(this).prop('checked') === false) {
                        // on stocke les univers enlevés dans un tableau
                        if (originalInterests.includes($(this).val()) && !removedInterests.includes($(this).val())) {
                            removedInterests.push($(this).val());
                        }
                    } else {
                        let index = removedInterests.indexOf($(this).val());
                        if (index !== -1) {
                            // Retirer l'élément du tableau
                            removedInterests.splice(index, 1);
                        }
                    }
                })
            });
        }

        function manageInterestsDisplay(availableInterestIds)
        {
            $.each(interestInputs, function(){
                if (availableInterestIds.includes(parseInt($(this).val()))) {
                    $(this).closest( ".field" ).removeClass('--hidden');
                } else {
                    $(this).prop( "checked", false );
                    $(this).closest( ".field" ).addClass('--hidden');
                }
            });
            interestLoader.addClass('--hidden');
            interestInputsWrapper.removeClass('--hidden');
        }

        function initializeBilledPosField()
        {
            let selectedOrganizations = [];
            if (selectOrganizations.val() != null) {
                selectedOrganizations = selectOrganizations.val();
            }

            let selectBilledPosOption = selectBilledPos.find('option');

            selectBilledPosOption.each(function(){
                let optionVal = $(this).val();
                if (!selectedOrganizations.includes(optionVal)) {
                    $(this).remove();
                }
            })

            selectBilledPos.select2();
        }

        function updateBilledPosSelect(selectedOrganizationId = null, action = null)
        {
            selectBilledPosLoader.removeClass('--hidden');
            selectBilledPosWrapper.addClass('--hidden');
            let selectedOrganizations = [];

            if (selectOrganizations.val()) {
                selectedOrganizations = selectOrganizations.val();
            }

            let lastBilledPosSelectedId = null;
            if (selectBilledPos.val()) {
                lastBilledPosSelectedId = selectBilledPos.val().toString();
            }

            if (selectedOrganizationId && action !== 'forceRemove') {
                selectedOrganizations.push(selectedOrganizationId)
            }

            selectBilledPos.find('option').remove();

            selectedOrganizations.forEach(function(val){
                if (action !== 'forceRemove' || selectedOrganizationId !== val) {
                    selectBilledPos.append($('<option>', {
                        value: val,
                        text: selectOrganizations.find('option[value="' + val + '"]').text()
                    }));
                }
            });

            // pre-select last selection
            if (lastBilledPosSelectedId && selectedOrganizations.includes(lastBilledPosSelectedId)) {
                selectBilledPos.val(lastBilledPosSelectedId);
            }

            selectBilledPos.select2();

            // simulate 3 seconds loading, treatment is too fast
            setTimeout(function () {
                selectBilledPosLoader.addClass('--hidden');
                selectBilledPosWrapper.removeClass('--hidden');
            }, 3000);
        }

        function handleArchiveUserAction()
        {
            archiveUserLink.click(function(e){
                e.preventDefault();
                let url = $(this).data('href');

                let requestHeaders = new Headers({
                    'X-Requested-With': 'XMLHttpRequest'
                });
                let requestInit = { method: 'GET',
                    headers: requestHeaders,
                };

                fetch(url, requestInit)
                    .then(response => response.json())
                    .then(data => {
                        if (data.redirectUrl) {
                            location.href = data.redirectUrl;
                        }
                        if (data.template) {
                            $('.user-archive-form-modal-content').html(data.template).removeClass('--hidden');
                            $('.user-archive-form-modal-loader-wrapper').addClass('--hidden');
                            let form = $('.reassign-lead-form-wrapper');
                            form.find('.confirm').on('click', function() {
                                validateFormAction();
                            })
                            form.find('.cancel').on('click', function() {
                                $('body > .pop-overlay').addClass('--hidden');
                                $('body > .pop-confirmation').addClass('--hidden');
                            })

                            const posSelect = $('.reaffectPosLeadLine-posSelect');
                            const sellerSelect = $('.reaffectPosLeadLine-sellerSelect');

                            posSelect.select2();
                            sellerSelect.select2();
                            updatePosListUser(posSelect);
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        console.log('Server error');
                    });
            })
        }

        function selectOrganizationsLoading()
        {
            selectOrganizationsWrapper.addClass('--hidden');
            selectOrganizationsLoader.removeClass('--hidden');
        }

        function selectOrganizationsLoaded()
        {
            selectOrganizationsWrapper.removeClass('--hidden');
            selectOrganizationsLoader.addClass('--hidden');
        }

        function handleUnarchiveUserAction()
        {
            unarchiveUserLink.click(function(e){
                e.preventDefault();
                $(this).addClass('--hidden');
                unarchiveUserLoader.removeClass('--hidden');

                let url = $(this).data('href');
                let requestHeaders = new Headers({
                    'X-Requested-With': 'XMLHttpRequest'
                });
                let requestInit = { method: 'GET',
                    headers: requestHeaders,
                };
                fetch(url, requestInit)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status == 200) {
                            location.reload();
                        }
                    })
                    .catch(function(error) {
                        console.log(error);
                        console.log('Server error');
                    });
            })
        }

        function handleChangeRoleAction()
        {
            if (selectRoles.val() === 'ROLE_GROUP_DIRECTOR' || selectRoles.val() === 'ROLE_SALES_MANAGER') {
                monthlyStatRoleInput.parents('.field').removeClass('--hidden');
                externalBillingRoleInput.parents('.field').removeClass('--hidden');
            } else {
                monthlyStatRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                externalBillingRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
            }
            selectRoles.on('change', function() {
                if ($(this).val() === 'ROLE_GROUP_DIRECTOR' || $(this).val() === 'ROLE_SALES_MANAGER') {
                    monthlyStatRoleInput.parents('.field').removeClass('--hidden');
                    externalBillingRoleInput.parents('.field').removeClass('--hidden');
                } else {
                    monthlyStatRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                    externalBillingRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                }
            })
            if (selectRoles.val() === 'ROLE_GROUP_DIRECTOR') {
                internalStatRoleInput.parents('.field').removeClass('--hidden');
            } else {
                internalStatRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
            }
            selectRoles.on('change', function() {
                if ($(this).val() === 'ROLE_GROUP_DIRECTOR') {
                    internalStatRoleInput.parents('.field').removeClass('--hidden');
                } else {
                    internalStatRoleInput.prop('checked', false).trigger('change').parents('.field').addClass('--hidden');
                }
            })
        }

        function statUserToggle()
        {
            if ( internalStatRoleInput.is(':checked') === false ) {
                blockLibelles.addClass('--hidden');
            }

            internalStatRoleInput.change(function(){
                if ($(this).is(':checked') === false) {
                    blockLibelles.addClass('--hidden');
                } else {
                    blockLibelles.removeClass('--hidden');
                }
            })
        }

        function validateFormAction(baseForm)
        {
            let form = $('.pop-confirmation .reassign-lead-form');
            let url = form.attr('action');
            let formSerialize = form.serialize();

            $('.user-archive-form-modal-content').addClass('--hidden');
            $('.user-archive-form-modal-loader-wrapper').removeClass('--hidden');
            $('.user-close-pos-form-modal-loader-wrapper').removeClass('--hidden');

            $.post(url, formSerialize, function(response) {
                if (response.interests) {
                    // on soumet le formulaire utilisateur
                    confirmationState = true;
                    baseForm.submit();
                } else if(response.status === 200) {
                    location.reload();
                }
            }).fail(function(error) {
                $('.user-archive-form-modal-loader-wrapper').addClass('--hidden');
                $('.user-archive-form-modal-content').html(error.statusText).removeClass('--hidden');
                toggleSectionContentVisibility();
                console.log(error);
                console.log('Server error');
            });
        }

    }

});
